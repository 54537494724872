<template>
  <div class="main-body" style="padding: 0">
    <div class="inner-wrapper">
      <div class="inner-main">
        <page-header
          :propConfig="page.header"
          :propModel="dataModel"
        ></page-header>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dataModel: {
        name: "change-password", //AQUI EN INGLES SINGULAR
        alias: "Cambiar contraseña",
        icon: "ri-lock-line",
      },
      page: {
        header: {
          menu: ["Cambiar contraseña"],
        },
      },
    };
  },
};
</script>